import {
  createFeatureSelector,
  createSelector,
}                   from '@ngrx/store';
import { pluck }    from '~utils/common';
import { AppState } from './app.state';

const selectState = createFeatureSelector<AppState>('app');

export const selectColorMode = createSelector(selectState, pluck('colorMode'));

export const selectMaintenanceMode = createSelector(selectState, pluck('maintenanceMode'));

export const selectAccountUser = createSelector(selectState, pluck('accountUser'));

export const selectOffice = createSelector(selectState, pluck('office'));

export const selectClientBadgeCount = createSelector(selectState, pluck('clientBadgeCount'));

export const selectNotificationBadgeCount = createSelector(selectState, pluck('notificationBadgeCount'));

export const selectPaymentBadgeCount = createSelector(selectState, pluck('paymentBadgeCount'));

export const selectUnlinkedAccountsBadgeCount = createSelector(selectState, pluck('unlinkedAccountsBadgeCount'));

export const selectAccountsWithWarningBadgeCount = createSelector(selectState, pluck('accountsWithWarningBadgeCount'));

export const selectCreateClientIsLoading = createSelector(selectState, pluck('createClientIsLoading'));

export const selectDocumentUpdating = createSelector(selectState, pluck('documentUpdating'));

export const selectDocumentDownloadingId = createSelector(selectState, pluck('documentDownloadingId'));

export const selectPaymentAccounts = createSelector(selectState, pluck('paymentAccounts'));

export const selectPaymentClients = createSelector(selectState, pluck('paymentClients'));

export const selectBeneficiaries = createSelector(selectState, pluck('beneficiaries'));

export const selectBeneficiariesLoading = createSelector(selectState, pluck('beneficiariesLoading'));

export const selectPaymentSaving = createSelector(selectState, pluck('paymentSaving'));

export const selectPaymentInfoLoading = createSelector(selectState, pluck('paymentInfoLoading'));

export const selectBeneficiaryEditing = createSelector(selectState, pluck('beneficiaryEditing'));
