import { Injectable }            from '@angular/core';
import { Store }                 from '@ngrx/store';
import {
  closeDialog,
  fetchMaintenanceMode,
}                                from '../state/app.actions';
import { selectMaintenanceMode } from '../state/app.selectors';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceModeService {
  maintenanceMode$ = this.store.select(selectMaintenanceMode);

  constructor(private readonly store: Store) {
  }

  fetchMaintenanceMode() {
    this.store.dispatch(fetchMaintenanceMode());
  }

  closeDialog() {
    this.store.dispatch(closeDialog({}));
  }
}
