import { BeneficiaryType } from '../enums';

export type Beneficiary = {
  id: string | null;
  global: boolean;
  counterpartName?: string;
  counterpartReference?: string;
  type: BeneficiaryType;
  singleUse?: boolean;
}

export type BeneficiaryFormData = {
  counterpartName: string;
  counterpartReference: string;
  type: 'single-use' | 'client' | 'global';
}

export function toBeneficiaryFormData(beneficary?: Beneficiary | null): BeneficiaryFormData | null {
  if (!beneficary) {
    return null;
  }
  return {
    counterpartName: beneficary.counterpartName ?? '',
    counterpartReference: beneficary.counterpartReference ?? '',
    type: getBeneficiaryType(beneficary),
  };
}

export function getBeneficiaryType(beneficiary: Beneficiary) {
  if (beneficiary.singleUse) {
    return 'single-use';
  }
  return beneficiary.global ? 'global' : 'client';
}
