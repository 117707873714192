import { HttpClient }       from '@angular/common/http';
import {
  Inject,
  Injectable,
}                           from '@angular/core';
import { Observable }       from 'rxjs';
import { MaintenanceModes } from '~domain/types';
import { ENVIRONMENT }      from '../app.constants';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceModeApiService {

  constructor(
    @Inject(ENVIRONMENT) private readonly environment: Environment,
    private readonly http: HttpClient,
  ) {
  }

  fetchMaintenanceModes(): Observable<MaintenanceModes> {
    return this.http.get<MaintenanceModes>(`${this.environment.api}/maintenance-modes`);
  }
}
