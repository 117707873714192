import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
}                                 from '@angular/core';
import { ColorModeService }       from '../core';
import { MaintenanceModeService } from './maintenance-mode.service';

@Component({
  selector: 'rb-maintenance-mode',
  templateUrl: './maintenance-mode.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenanceModeComponent implements OnInit {
  colorMode$ = this.colorModeService.colorMode$;

  constructor(
    private readonly colorModeService: ColorModeService,
    private readonly maintenanceModeService: MaintenanceModeService,
  ) {
  }

  setColorMode(colorMode: 'dark' | 'light' | null) {
    this.colorModeService.setColorMode(colorMode);
  }

  ngOnInit() {
    this.maintenanceModeService.closeDialog();
  }
}
