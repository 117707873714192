import {
  Component,
  HostBinding,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
}                                 from '@angular/core';
import {
  interval,
  map,
  Subject,
  takeUntil,
}                                 from 'rxjs';
import packageJson                from '../../package.json';
import { ENVIRONMENT }            from './app.constants';
import {
  ColorModeService,
  UpdateService,
}                                 from './core';
import { MaintenanceModeService } from './maintenance-mode/maintenance-mode.service';

@Component({
  selector: 'rb-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  @HostBinding('attr.app-version')
  readonly appVersion = packageJson.version;
  darkModeEnabled$ = this.colorModeService.colorMode$.pipe(
    map(colorMode => colorMode === 'dark' || (!colorMode && window.matchMedia('(prefers-color-scheme: dark)').matches)),
  );
  maintenanceMode$ = this.maintenanceModeService.maintenanceMode$;
  private destroy$ = new Subject<void>();

  constructor(
    @Inject(ENVIRONMENT) private readonly environment: Environment,
    private readonly colorModeService: ColorModeService,
    private readonly maintenanceModeService: MaintenanceModeService,
    private readonly updateService: UpdateService,
  ) {
  }

  ngOnInit() {
    this.colorModeService.fetchColorMode();
    this.pollForMaintenanceMode();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('document:visibilitychange', ['$event'])
  onVisibilityChange(event: Event) {
    const { visibilityState } = event.target as Document;
    if (visibilityState === 'visible') {
      this.updateService.checkForUpdate();
      this.maintenanceModeService.fetchMaintenanceMode();
    }
  }

  private pollForMaintenanceMode() {
    this.maintenanceModeService.fetchMaintenanceMode();
    interval(60000) // every minute
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.maintenanceModeService.fetchMaintenanceMode());
  }
}
