import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
}                             from '@angular/core';
import {
  AbstractControl,
  NG_ASYNC_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NonNullableFormBuilder,
  ValidationErrors,
  ValidatorFn,
}                             from '@angular/forms';
import { ViewChildDirective } from '~app/core';
import {
  BeneficiaryType,
  UpdateTransactionType,
}                             from '~domain/enums';
import {
  Beneficiary,
  BeneficiaryFormData,
  getBeneficiaryType,
}                             from '~domain/types';
import { RbForm }             from '~shared/directives';
import {
  CustomValidators,
  ValidatorService,
}                             from '../core/validators';

export type EditBeneficiaryRequest = {
  clientId: string,
  beneficiary: Beneficiary
}

@Component({
  selector: 'rb-edit-beneficiary',
  templateUrl: './edit-beneficiary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditBeneficiaryComponent),
      multi: true,
    },
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => EditBeneficiaryComponent),
      multi: true,
    },
  ],
})
export class EditBeneficiaryComponent extends RbForm implements OnInit {

  @Input({ required: true })
  id: string | null = null;
  @Input()
  beneficiaryEditing: boolean | null = false;
  @Input({ required: true })
  beneficiaries: Beneficiary[] | null = [];
  @Output()
  confirm = new EventEmitter<Beneficiary>();
  @Output()
  cancel = new EventEmitter<void>();
  @ViewChild(ViewChildDirective)
  nameInput?: ViewChildDirective;

  override form = this.fb.group({
    counterpartName: this.fb.control('', { validators: [this.nameValidator()] }),
    counterpartReference: this.fb.control('', {
      asyncValidators: [CustomValidators.ibanValidator(this.validatorService)],
    }),
    type: this.fb.control<'single-use' | 'client' | 'global'>('single-use'),
  }, {
    validators: [this.uniquenessValidator()],
  });
  protected readonly UpdateTransactionType = UpdateTransactionType;

  constructor(protected override readonly fb: NonNullableFormBuilder,
              protected override readonly validatorService: ValidatorService) {
    super(fb, validatorService);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.nameInput?.focus();
  }

  override writeValue(beneficiary: BeneficiaryFormData): void {
    this.form.setValue(beneficiary);
    this.form.controls.counterpartReference.markAsTouched();
    setTimeout(() => {
      this.form.controls.counterpartReference.updateValueAndValidity();
    });
  }

  confirmEditBeneficiary() {
    if (this.form.valid) {
      const beneficiary: Beneficiary = {
        counterpartName: this.form.controls.counterpartName.value,
        counterpartReference: this.form.controls.counterpartReference.value.replace(/\s/g, '').toUpperCase(),
        singleUse: this.form.controls.type.value === 'single-use',
        global: this.form.controls.type.value === 'global',
        type: BeneficiaryType.BENEFICIARY,
        id: this.id,
      };
      this.confirm.emit(beneficiary);
    }
  }

  cancelEditBeneficiary() {
    this.cancel.emit();
  }

  private uniquenessValidator(): ValidatorFn {
    const key = 'unique';
    const errors: ValidationErrors = { unique: true };

    return (group: AbstractControl) => {
      const counterpartReferenceControl = group.get('counterpartReference');
      const typeControl = group.get('type');

      if (
        counterpartReferenceControl?.errors && !counterpartReferenceControl.errors[key]
      ) {
        // Another error with higher priority is already present
        return null;
      }

      const counterpartReference = counterpartReferenceControl?.value?.replace(/\s/g, '').toUpperCase() ?? '';
      const type = typeControl?.value ?? 'single-use';

      if (this.beneficiaries?.some(
        beneficiary => beneficiary.counterpartReference?.replace(/\s/g, '').toUpperCase() === counterpartReference
          && type === getBeneficiaryType(beneficiary)
          && beneficiary.id !== this.id,
      )) {
        counterpartReferenceControl?.setErrors(errors);
        return errors;
      } else {
        counterpartReferenceControl?.setErrors(null);
        return null;
      }
    };
  }

  private nameValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      if (CustomValidators.validCounterpartNameOrUnstructuredRemittanceInfo(control.value)) {
        return { beneficiaryName: true };
      }
      return null;
    };
  }
}
