<div class="h-full w-full absolute bg-login bg-cover"></div>
<div class="h-full w-full absolute flex justify-center items-center bg-primary bg-opacity-50">
  <mat-card class="w-[600px] p-[2rem] bg-primary bg-opacity-65 backdrop-blur-lg rounded-[2rem] shadow-card">
    <div class="flex justify-center items-center mt-[1rem] mb-[2rem] h-[4rem]">
      <img alt="roov-logo" class="h-[4rem]" src="assets/img/roov-logo.svg" />
    </div>
    <mat-card-header class="p-[1rem]">
      <mat-card-title class="w-full text-center text-lg text-primary">{{
          'MAINTENANCE.TITLE' | translate
        }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="p-[1rem] text-secondary">
      <div>{{ 'MAINTENANCE.MESSAGE' | translate }}</div>
      <div class="flex justify-center py-[2rem]">
        <mat-icon class="icon-40 icon-primary" svgIcon="maintenance"></mat-icon>
      </div>
      <div *ngrxLet="colorMode$ as colorMode" class="flex gap-[10px]">
        <mat-icon (click)="setColorMode('light')"
                  [ngClass]="colorMode === 'light' ? 'icon-secondary' : 'icon-tertiary'"
                  class="icon-20 cursor-pointer hover:icon-secondary"
                  matListItemIcon
                  matTooltip="{{ 'NAVBAR.COLOR_MODE.light' | translate }}"
                  svgIcon="sun"></mat-icon>
        <mat-icon (click)="setColorMode('dark')"
                  [ngClass]="colorMode === 'dark' ? 'icon-secondary' : 'icon-tertiary'"
                  class="icon-20 cursor-pointer hover:icon-secondary"
                  matListItemIcon
                  matTooltip="{{ 'NAVBAR.COLOR_MODE.dark' | translate }}"
                  svgIcon="moon"></mat-icon>
        <mat-icon (click)="setColorMode(null)"
                  [ngClass]="colorMode === null ? 'icon-secondary' : 'icon-tertiary'"
                  class="icon-20 cursor-pointer hover:icon-secondary"
                  matListItemIcon
                  matTooltip="{{ 'NAVBAR.COLOR_MODE.system' | translate }}"
                  svgIcon="system"></mat-icon>
      </div>
    </mat-card-content>
  </mat-card>
</div>
